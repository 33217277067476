<template>
  <v-main class="bg-body-color main">
    <div class="wrapper__auth-new" style="justify-content: flex-start;">
      <div class="auth__logo" @click="redirectToGeorApp">
        <img src="@/assets/images/logo.svg" alt="Geor, iniciar sesión" />
      </div>
      <div class="auth__box">
        <span class="title__auth">Recuperar contraseña</span>
        <p class="auth__box-p" style="font-size: 14px; font-weight: 400;">
          Ingresa tu correo electrónico te enviaremos un código para recuperar tu contraseña.
        </p>
        <br />
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formForgotPassword">
          <form @submit.prevent="onSubmit">
            <ValidationProvider name="correo electrónico" vid="email" rules="required|max:190|email" v-slot="{ errors }"
              style="padding-top: 0px;
              margin-top: 0px;
              margin-bottom: 24px;">
              <v-text-field v-model="form.email" label="Correo electrónico" :error-messages="errors" type="email"
                name="email" :readonly="hasSendCode"></v-text-field>
              <!-- :disabled="hasSendCode" -->
              <div class="text-center">
                <v-btn v-if="!hasSendCode" :disabled="invalid || loading" color="primary" depressed type="submit" block
                  :loading="loading" class="v-btn-pad-x-large btn-new"
                  style="border-radius: 5px !important;"
                  ><span
                    class="text-button-new">Continuar</span></v-btn>
              </div>
            </ValidationProvider>
            <template v-if="hasSendCode">
              <ValidationProvider name="código" vid="code" rules="required|digits:6" v-slot="{ errors }">
                <v-text-field v-model="form.code" label="Código de verificación" type="number"
                  :error-messages="errors" ></v-text-field>
              </ValidationProvider>
              <!-- <span style="padding-top: 0px; font-size: 14px !important;margin-top: -40px!important;">
                ¿No recibiste el correo? <router-link :disabled="loading" :to="{ name: '' }"
                  style="text-decoration: underline;">
                  <small style="font-size: 14px !important;" @click="onResendCode()">Reenviar</small>
                </router-link>
              </span> -->
              <ValidationProvider name="contraseña" vid="password" rules="required|min:6|max:50" v-slot="{ errors }">
                <v-text-field v-model="form.password" label="Nueva contraseña" :error-messages="errors"
                  :type="showPassword ? 'text' : 'password'">
                  <template v-slot:append>
                    <img v-if="showPassword" src="@/assets/images/icon-eye.svg" alt="Ojo abierto"
                      @click="showPassword = !showPassword" style="cursor: pointer;">
                    <img v-else src="@/assets/images/icon-eye-close.svg" alt="Ojo cerrado"
                      @click="showPassword = !showPassword" style="cursor: pointer;">
                  </template>
                </v-text-field>
              </ValidationProvider>

              <div class="text-center">
                <v-btn :disabled="invalid || loading" color="primary" depressed type="submit" block :loading="loading"
                  class="v-btn-pad-x-large btn-new"
                  style="border-radius: 5px !important;"
                  ><span class="text-button-new">Recuperar contraseña</span></v-btn>
              </div>
            </template>
          </form>
        </ValidationObserver>
      </div>
      <span v-if="hasSendCode" style="padding-top: 4px; font-size: 14px !important; padding-bottom: 16px;">
        ¿No recibiste el código? <span
        :disabled="loading" @click="onResendCode" style="text-decoration: underline; color: #0375f8;">
          <small style="font-size: 14px !important; cursor: pointer;">Reenviar</small>
      </span>
    </span>
      <router-link :to="{ name: 'LoginEmail' }" :disabled="loading" style="padding-block: 10px; height: 40px;
            padding-inline: 22px; cursor: pointer;">
                <small style="font-size: 14px !important;">VOLVER A INICIAR SESIÓN</small>
            </router-link>
      <!-- <BtnWhatsapp /> -->
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
// import BtnWhatsapp from "../components/BtnWhatsapp.vue";

export default {
  components: {
    // BtnWhatsapp,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      btnText: "Recuperar contraseña",
      hasSendCode: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
        code: "",
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    onSubmit() {
      if (this.hasSendCode) {
        this.$axios
          .post("auth/forgot-password/check-code", this.form)
          .then((response) => {
            const { data } = response;
            this.$toast.success(data.message);
            this.hasSendCode = false;
            this.form.email = "";
            this.form.code = "";
            this.form.password = "";
            this.btnText = "Recuperar contraseña";
            this.$router.push('/login-email');
          })
          .catch((error) => this.showErrors(error, "formForgotPassword"));
      } else {
        this.$axios
          .post("auth/forgot-password/send-email/send", this.form)
          .then((response) => {
            const { data } = response;
            this.$toast.success(data.message);
            this.hasSendCode = true;
            this.btnText = "Recuperar contraseña";
          })
          .catch((error) => this.showErrors(error, "formForgotPassword"));
      }
    },
    onResendCode() {
      const payload = {
        email: this.form.email,
      };

      this.$axios.post("auth/forgot-password/send-email/resend", payload).then((response) => {
        const { data } = response;
        this.$toast.success(data.message);
      });
    },
    redirectToGeorApp() {
      // Redirecciona a la ruta externa
      window.location.href = 'https://geor.app/';
    },
  },
};
</script>
