var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"bg-body-color main"},[_c('div',{staticClass:"wrapper__auth-new",staticStyle:{"justify-content":"flex-start"}},[_c('div',{staticClass:"auth__logo",on:{"click":_vm.redirectToGeorApp}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Geor, iniciar sesión"}})]),_c('div',{staticClass:"auth__box"},[_c('span',{staticClass:"title__auth"},[_vm._v("Recuperar contraseña")]),_c('p',{staticClass:"auth__box-p",staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(" Ingresa tu correo electrónico te enviaremos un código para recuperar tu contraseña. ")]),_c('br'),_c('ValidationObserver',{ref:"formForgotPassword",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('ValidationProvider',{staticStyle:{"padding-top":"0px","margin-top":"0px","margin-bottom":"24px"},attrs:{"name":"correo electrónico","vid":"email","rules":"required|max:190|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","error-messages":errors,"type":"email","name":"email","readonly":_vm.hasSendCode},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"text-center"},[(!_vm.hasSendCode)?_c('v-btn',{staticClass:"v-btn-pad-x-large btn-new",staticStyle:{"border-radius":"5px !important"},attrs:{"disabled":invalid || _vm.loading,"color":"primary","depressed":"","type":"submit","block":"","loading":_vm.loading}},[_c('span',{staticClass:"text-button-new"},[_vm._v("Continuar")])]):_vm._e()],1)]}}],null,true)}),(_vm.hasSendCode)?[_c('ValidationProvider',{attrs:{"name":"código","vid":"code","rules":"required|digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Código de verificación","type":"number","error-messages":errors},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"contraseña","vid":"password","rules":"required|min:6|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nueva contraseña","error-messages":errors,"type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye.svg"),"alt":"Ojo abierto"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye-close.svg"),"alt":"Ojo cerrado"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"v-btn-pad-x-large btn-new",staticStyle:{"border-radius":"5px !important"},attrs:{"disabled":invalid || _vm.loading,"color":"primary","depressed":"","type":"submit","block":"","loading":_vm.loading}},[_c('span',{staticClass:"text-button-new"},[_vm._v("Recuperar contraseña")])])],1)]:_vm._e()],2)]}}])})],1),(_vm.hasSendCode)?_c('span',{staticStyle:{"padding-top":"4px","font-size":"14px !important","padding-bottom":"16px"}},[_vm._v(" ¿No recibiste el código? "),_c('span',{staticStyle:{"text-decoration":"underline","color":"#0375f8"},attrs:{"disabled":_vm.loading},on:{"click":_vm.onResendCode}},[_c('small',{staticStyle:{"font-size":"14px !important","cursor":"pointer"}},[_vm._v("Reenviar")])])]):_vm._e(),_c('router-link',{staticStyle:{"padding-block":"10px","height":"40px","padding-inline":"22px","cursor":"pointer"},attrs:{"to":{ name: 'LoginEmail' },"disabled":_vm.loading}},[_c('small',{staticStyle:{"font-size":"14px !important"}},[_vm._v("VOLVER A INICIAR SESIÓN")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }